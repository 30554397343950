import React from "react";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import { Col, Container, Row } from "react-bootstrap";

import breakDownAllNodes from "utils/breakDownAllNodes";
import nl2br from "utils/nl2br";
import Wrapper from "containers/Wrapper";
import Form from "containers/Form";

export const query = graphql`
  query ContactQuery($langKey: String!) {
    allMarkdownRemark(
      filter: { fields: { langKey: { eq: $langKey } } }
      sort: { order: ASC, fields: [fields___directoryName, fields___fileName] }
    ) {
      nodes {
        frontmatter {
          imageFileName
          anchor
          sections {
            anchor
          }
          copyright
          social {
            linkedin
          }
          privacyText
          termsText
          
          titleTemplate
          keywords
          description
          
          labelName
          feedbackName
          labelEmail
          feedbackEmail
          labelMessage
          feedbackMsg
          descMessage
          buttonText
          textSuccess
          fetchUrl
          errorMsg
          
          serviceForm
          contentForm
          headerForm
          headerPanelRight
          descPanelRight
          emailPanelRight
        }
        fields {
          fileName
          directoryName
        }
      }
    }
  }
`;

const ContactPage = ({ pathContext, data }) => {
  const { allMarkdownRemark: { nodes } } = data;
  const { contactNode, formNode } = breakDownAllNodes(nodes);

  const {
    frontmatter: {
      serviceForm, headerForm, headerPanelRight, descPanelRight, emailPanelRight,
    },
  } = contactNode;

  return (
    <Wrapper
      pathContext={pathContext}
      nodes={nodes}
      node={contactNode}
      className='my-auto'
    >

      <div className='d-flex align-items-center my-0 my-sm-3 text-body'>
        <Container>
          <Row>

            <Col lg={8} className='px-0 pr-lg-3'>
              <div className='bg-white-transparent py-5 px-3 px-sm-5 h-100'>

                <Form
                  headerForm={headerForm}
                  frontmatter={formNode.frontmatter}
                  service={serviceForm}
                />

              </div>
            </Col>

            <Col lg={4} className='px-0'>
              <div
                className='bg-white-transparent pt-0 pt-lg-5 pb-5 px-3 px-sm-5 h-100'>
                <h2 className='mb-3'>{headerPanelRight}</h2>
                <p className='mb-0'>
                  {`${nl2br(descPanelRight)} `}
                  <a href={`mailto:${emailPanelRight}`}>{emailPanelRight}</a>.
                </p>
              </div>
            </Col>

          </Row>

        </Container>
      </div>
    </Wrapper>
  );
};

ContactPage.propTypes = {
  data: PropTypes.object.isRequired,
  pathContext: PropTypes.object,
};

ContactPage.defaultProps = {
  pathContext: {
    langKey: "de",
    defaultLang: "de",
    langTextMap: {},
  },
};

export default ContactPage;
